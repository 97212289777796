import { apiClient } from '@/app/api';

export async function fetchCampaignById(campaignId) {
  try {
    return await apiClient.get(`api/v1/campaign/${campaignId}`);
  } catch (e) {
    throw new Error('Error while fetching campaign detail with error: ', e);
  }
}

export async function fetchCampaignsListByWorkspaceId(workspaceId) {
  try {
    return await apiClient.get(`/api/v1/campaign?workspaceId=${workspaceId}`);
  } catch (e) {
    throw new Error('Error while fetching campaigns list with error: ', e);
  }
}

export async function createCampaign(workspaceId, campaignName) {
  try {
    return await apiClient.post(`api/v1/campaign`, { workspaceId: workspaceId, name: campaignName });
  } catch (e) {
    throw new Error('Error while creating campaign with error: ', e);
  }
}

export async function updateCampaign(campaignData) {
  try {
    return await apiClient.put(`api/v1/campaign/${campaignData.id}`, campaignData);
  } catch (e) {
    throw new Error('Error while updating campaign with error: ', e);
  }
}

export async function deleteCampaign(campaign) {
  try {
    return await apiClient.delete(`api/v1/campaign/${campaign.id}`);
  } catch (e) {
    throw new Error('Error while deleting campaign with error: ', e);
  }
}

export async function confirmCampaignSetup(campaignId, contractIds) {
  return await apiClient.post(`api/v1/campaign/${campaignId}/contract`, { contractIds });
}

export async function startCampaignManually(campaignId) {
  try {
    return await apiClient.patch(`api/v1/campaign/${campaignId}/start`);
  } catch (e) {
    throw new Error('Error while confirming campaign with error: ', e);
  }
}

export async function fetchCampaignContractsById(workspaceId, payload) {
  try {
    return await apiClient.post(`api/v1/workspaces/${workspaceId}/contracts`, payload);
  } catch (e) {
    throw new Error('Error while fetching campaign contracts with error: ', e);
  }
}
