import { render, staticRenderFns } from "./Documents.vue?vue&type=template&id=72b54123&scoped=true"
import script from "./Documents.vue?vue&type=script&lang=js"
export * from "./Documents.vue?vue&type=script&lang=js"
import style0 from "./Documents.vue?vue&type=style&index=0&id=72b54123&prod&lang=scss&scoped=true"
import style1 from "./Documents.vue?vue&type=style&index=1&id=72b54123&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72b54123",
  null
  
)

export default component.exports