<template lang="html">
  <v-card
    :style="`box-shadow: inset 2px 0px 0px 0px ${contractStateColor}`"
    class="document-table-row d-flex align-center pb-md-0 pb-6"
    tile
  >
    <v-row
      align="start"
      class="px-4"
      :class="{ 'justify-space-between': isCampaignDetailRoute, 'pr-7': isCampaignDetailRoute }"
    >
      <v-col
        v-if="areCheckboxesEnabled"
        cols="1"
        class="document-table-row__select d-none d-md-flex order-md-0"
      >
        <v-checkbox
          v-model="isChecked"
          class="mt-0 pt-0"
          hide-details
          @click.prevent
        />
      </v-col>

      <v-col
        cols="12"
        md="3"
        class="document-table-row__width--225 order-md-1 pb-0"
      >
        <router-link
          :to="{
            name: 'documentsDetail',
            params: { workspace_id: workspaceId, contract_id: item.id },
            query: { campaignDetail: isCampaignDetailRoute },
          }"
          :title="item.title"
          class="document-table-row__title d-inline-block link-unstyled mb-5"
        >
          <div class="d-flex align-center flex-nowrap pr-2">
            <v-checkbox
              v-if="areCheckboxesEnabled"
              v-model="isChecked"
              class="d-md-none mb-6"
              hide-details
              @click.prevent
            >
              <template v-slot:label>
                <router-link
                  :to="{
                    name: 'documentsDetail',
                    params: { workspace_id: workspaceId, contract_id: item.id },
                    query: { campaignDetail: isCampaignDetailRoute },
                  }"
                  :title="item.title"
                  class="document-table-row__title link-unstyled"
                >
                  {{ item.title }}
                </router-link>
              </template>
            </v-checkbox>

            <span
              class="d-md-flex"
              :class="{ 'd-none': areCheckboxesEnabled }"
            >
              {{ item.title }}
            </span>

            <div
              v-if="item.attachment_count"
              class="document-table-row__attachments d-flex ml-1"
            >
              <v-icon
                color="#B3C2D6"
                small
              >
                mdi-paperclip
              </v-icon>

              {{ item.attachment_count }}
            </div>

            <span
              v-if="isPdfDeleted && isCompleted"
              class="ml-1"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="#B3C2D6"
                    small
                  >
                    mdi-text-box-remove-outline
                  </v-icon>
                </template>

                <span>
                  {{ $t('contract.deleted.pdf') }}
                </span>
              </v-tooltip>
            </span>

            <span
              v-if="hasDocsAttachments"
              class="ml-1"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="#B3C2D6"
                    small
                  >
                    mdi-file-outline
                  </v-icon>
                </template>

                <span>
                  {{ $t('docs.uploaded_attachment') }}
                </span>
              </v-tooltip>
            </span>

            <span
              v-if="hasDocsAttachmentsNotes"
              class="ml-1"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="#B3C2D6"
                    small
                  >
                    mdi-comment-text-outline
                  </v-icon>
                </template>

                <span>
                  {{ $t('docs.uploaded_attachment.with_comment') }}
                </span>
              </v-tooltip>
            </span>

            <span class="ml-1">
              <ArchiveState
                :archive-state="archiveState"
                small
              />
            </span>
          </div>
        </router-link>

        <div class="document-table-row__contract--persons">
          <span class="document-table-row__label"> {{ $t('general.creator') }}: </span>
          <span class="ml-1"> {{ item.creator }} </span>
        </div>
      </v-col>

      <v-col
        v-if="identities.length > 0"
        class="col-12 pt-md-0 order-md-8 mt-0 pl-3"
        :style="areCheckboxesEnabled ? 'pl-md-14' : ''"
      >
        <v-row
          align="center"
          justify="start"
          no-gutters
        >
          <v-col class="col-auto pr-2">
            <span class="document-table-row__label"> {{ $t('contract.signers') }}: </span>
          </v-col>

          <SignIdentityState
            v-for="signIdentity in identities"
            :key="signIdentity.id"
            :sign-identity="signIdentity"
          ></SignIdentityState>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        md="1"
        class="pt-md-3 px-lg-0 ml-lg-5 mr-lg-2 order-md-5 pt-0"
      >
        <div class="document-table-row__name">
          <span class="document-table-row__label d-md-none"> {{ $t('general.created_at') }}: </span>

          {{ formatDate(item.dates.created) }}
        </div>
      </v-col>

      <v-col
        cols="12"
        md="2"
        lg="1"
        class="d-none d-lg-flex pt-md-3 px-lg-0 ml-lg-3 order-md-6 pt-0"
      >
        <div class="document-table-row__name">
          <span class="document-table-row__label d-md-none"> {{ $t('general.updated_at') }}:</span>

          {{ item.dates.expireAt ? formatDate(item.dates.expireAt) : '' }}
        </div>
      </v-col>

      <v-col
        cols="12"
        md="2"
        class="document-table-row__width--155 order-md-3"
      >
        <div class="document-table-row__name document-table-row__tags pr-md-1 pr-0">
          <span
            v-if="item.tags.length > 0"
            class="document-table-row__label d-md-none"
          >
            {{ $t('tags') }}:
          </span>
          <span
            v-for="tag in item.tags"
            :key="tag.id"
          >
            <v-chip
              color="#95AFDA"
              class="document-table-row__tags--chip mb-1 ml-1"
              outlined
              label
              small
            >
              <span class="document-table-row__tags--text">
                {{ tag.name }}
              </span>
            </v-chip>
          </span>
        </div>
      </v-col>

      <v-col
        cols="12"
        md="2"
        class="document-table-row__width--155 pt-md-3 order-md-4 pt-0"
      >
        <div class="document-table-row__name">
          <span class="document-table-row__label d-md-none"> {{ $t('folders.title') }}: </span>
          <span class="document-table-row__folders">{{ item.folder.name }}</span>
        </div>
      </v-col>

      <v-col
        cols="12"
        md="2"
        class="document-table-row__width--155 order-md-2"
      >
        <DocumentState
          :state-type="item.state"
          width="full"
          @stateColor="assignStateColor"
        />
      </v-col>

      <v-menu
        v-if="!isCampaignDetailRoute"
        offset-y
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="document-table-row__menu"
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="#95AFDA">
              {{ `mdi-dots-${$vuetify.breakpoint.mdAndDown ? 'vertical' : 'horizontal'}` }}
            </v-icon>
          </v-btn>
        </template>

        <v-list class="px-2 py-3">
          <v-list-item
            v-if="hasRowFeatureFlag(item, 'isDuplicatable')"
            class="document-table-row__menu__item"
          >
            <IcoDuplicate />
            <a
              href="#"
              class="pl-2"
              @click.prevent="openModal('duplicate', item)"
              style="font-weight: 400"
            >
              {{ $t('contract.duplicate') }}
            </a>
          </v-list-item>

          <v-list-item class="document-table-row__menu__item">
            <IcoMoveToFolder />
            <a
              href="#"
              class="pl-2"
              @click.prevent="openModal('move', item)"
            >
              {{ $t('folders.moveToFolder') }}
            </a>
          </v-list-item>

          <v-list-item class="document-table-row__menu__item">
            <IcoMoveToFolder />
            <a
              href="#"
              class="pl-2"
              @click.prevent="openModal('editTags', item)"
            >
              {{ $t('tags.edit') }}
            </a>
          </v-list-item>

          <v-list-item
            v-if="canChangeExpirationDate"
            class="document-table-row__menu__item"
          >
            <IcoExpiration />
            <a
              href="#"
              class="pl-2"
              @click.prevent="openModal('extendExpiration', item)"
            >
              {{ $t('docs.pending_expiration.extend') }}
            </a>
          </v-list-item>

          <v-divider v-if="hasRowFeatureFlag(item, 'isCompletelyDeletable')"></v-divider>

          <v-list-item
            v-if="hasRowFeatureFlag(item, 'isCompletelyDeletable')"
            class="document-table-row__menu__item"
          >
            <IcoTrash />
            <a
              href="#"
              class="pl-2"
              @click.prevent="showDeleteContractDialog = true"
            >
              {{ $t('general.delete') }}
            </a>
          </v-list-item>

          <v-list-item
            v-if="canDeletePdf"
            class="document-table-row__menu__item"
          >
            <IcoTrash />
            <a
              href="#"
              class="pl-2"
              @click.prevent="showCompletedDocumentDeletePdfDialog = true"
            >
              {{ $t('general.delete_pdf') }}
            </a>
          </v-list-item>
        </v-list>
      </v-menu>

      <SConfirmDialog
        v-if="showDeleteContractDialog"
        v-model="showDeleteContractDialog"
        :message="`${$t('docs.delete.confirm')}`"
        @confirmation-callback="deleteContract"
      />

      <SConfirmDialog
        v-if="showCompletedDocumentDeletePdfDialog"
        v-model="showCompletedDocumentDeletePdfDialog"
        :message="`${$t('contract.delete_pdf.info')}`"
        @confirmation-callback="deleteContractPdf"
      />
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { defineComponent } from 'vue';
import { ContractService } from '@/services/ContractService';
import featureFlagsChecker from '@/common/reusable/featureFlagsChecker';
import { hasFeatureFlag } from '@/app/common/utils/features';
import { getFormattedDateByLocale } from '@/common/reusable/dateFunctions';
import ArchiveState from '@/features/contract/components/ArchiveState.vue';
import DocumentState from '@/app/components/DocumentState.vue';
import IcoTrash from '@/views/dashboard/components/icons/IcoTrash.vue';
import IcoExpiration from '@/components/svg/IcoExpiration.vue';
import IcoMoveToFolder from '@/components/svg/IcoMoveToFolder.vue';
import IcoDuplicate from '@/components/svg/IcoDuplicate.vue';
import SConfirmDialog from '@/common/components/SConfirmDialog.vue';
import SignIdentityState from '@/features/dashboard/components/SignIdentityState.vue';

export default defineComponent({
  name: 'DocumentTableRow',
  components: {
    SignIdentityState,
    ArchiveState,
    DocumentState,
    IcoTrash,
    IcoExpiration,
    IcoMoveToFolder,
    IcoDuplicate,
    SConfirmDialog,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const formatDate = (date) =>
      getFormattedDateByLocale({ date, options: { year: 'numeric', month: 'numeric', day: 'numeric' } });

    return { formatDate };
  },
  data() {
    return {
      showDeleteContractDialog: false,
      identities: [],
      popupActionsOpen: false,
      showCompletedDocumentDeletePdfDialog: false,
      selectedWorkspace: null,
      contractStateColor: '',
    };
  },
  computed: {
    ...mapGetters({ dashboardCheckedDocuments: 'dashboardCheckedDocuments', workspaceById: 'workspaceById' }),
    isChecked: {
      get() {
        return this.dashboardCheckedDocuments.some((document) => document.id === this.item.id);
      },
      set() {
        this.$store.commit('dashboardCheckDocument', this.item);
      },
    },
    currentWorkspace() {
      return this.workspaceById(this.workspaceId);
    },
    isEnabledMovingBetweenWorkspaces() {
      return featureFlagsChecker.enableMovingContracts(this.currentWorkspace);
    },
    isWorkspaceSelected() {
      return !this.selectedWorkspace;
    },
    archiveState() {
      return this.item?.state_trusted_archive || null;
    },
    canChangeExpirationDate() {
      const isExpirationExtendable = this.item?.feature_flags?.isExpirationExtendable || false;
      const unsupportedStates = ['draft', 'expired', 'rejected', 'completed'];

      return !unsupportedStates.includes(this.item.state_dashboard) && isExpirationExtendable;
    },
    canDeletePdf() {
      return this.archiveState?.status !== 'archived' && this.item?.feature_flags?.canDeleteDocuments;
    },
    isPdfDeleted() {
      return this.item?.is_all_deleted_by_client;
    },
    isCompleted() {
      return 'completed' === this.item?.state;
    },
    workspaceId() {
      return this.$route.params.workspace_id || this.$route.params.workspaceId;
    },
    hasDocsAttachments() {
      return this.item?.has_docs_attachments;
    },
    hasDocsAttachmentsNotes() {
      return this.item?.has_docs_attachments_notes;
    },
    isCampaignDetailRoute() {
      return 'dashboard--campaign-detail' === this.$route.name;
    },
    areCheckboxesEnabled() {
      if (this.isCampaignDetailRoute) {
        return false;
      }

      return featureFlagsChecker.enableMassOperations(this.currentWorkspace);
    },
  },
  created() {
    this.setupIdentities();
  },
  methods: {
    onClickOutside() {
      this.popupActionsOpen = false;
    },
    deleteContract() {
      ContractService.deleteDocs(this.item.docs_id).then(
        () => {
          this.$store.dispatch('getUserProfile');
          this.$notification.success(this.$t('docs.delete.ok'));
          this.$emit('reload');
        },
        () => {
          this.$notification.error(this.$t('docs.delete_draft.failed'));
        },
      );
    },
    deleteContractPdf() {
      ContractService.deleteCompleted(this.item.id)
        .then((response) => {
          if ('ok' === response.status) {
            return (() => {
              this.$notification.success(this.$t('docs.delete.ok'));
              this.$emit('reload');
            })();
          }

          this.$notification.error(this.$t('docs.delete_draft.failed'));
        })
        .catch(() => {
          this.$notification.error(this.$t('docs.delete_draft.failed'));
        });
    },
    setupIdentities() {
      this.identities = this.item.people;
    },
    openModal(action, item) {
      this.popupActionsOpen = false;
      this.$store.commit('dashboardOpenModal', { type: action, contracts: [item] });
    },
    hasRowFeatureFlag(item, flag) {
      return hasFeatureFlag(item, flag);
    },
    assignStateColor(color) {
      this.contractStateColor = color;
    },
  },
});
</script>

<style lang="scss" scoped>
.document-table-row {
  border-top: 1px solid #d4dff0;
  box-sizing: border-box;
  min-height: 9rem;

  &__select {
    @media only screen and (min-width: 960px) {
      margin-right: -1rem;
    }

    @media only screen and (min-width: 1200px) {
      margin-right: -3rem;
    }
  }

  &__width {
    &--225 {
      max-width: 225px;
    }

    &--155 {
      @media only screen and (min-width: 960px) {
        max-width: 155px;
      }
    }
  }

  &__label {
    font-size: 14px;
    color: #868e96 !important;
  }

  &__name {
    font-size: 14px;
    line-height: 17px;
    color: #424242 !important;

    @media only screen and (min-width: 1260px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__signers {
    @media only screen and (min-width: 960px) {
      margin-left: -1rem;
    }

    @media only screen and (min-width: 1260px) {
      margin-left: -3rem;
    }
  }

  &__tags {
    max-height: 50px;

    &--chip {
      font-size: 11px;
      font-weight: bold;
      padding: 0 8px;
    }

    &--text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__folders {
    max-height: 50px;
    font-size: 11px;
    padding: 0 8px;
  }

  &__campaign {
    max-height: 50px;
    font-size: 11px;
    padding: 0 8px;
  }

  &__title {
    font-size: 14px;
    color: #424242 !important;
  }

  &__contract {
    &--persons {
      font-size: 14px;
      color: #424242 !important;
    }

    &--trimmed {
      display: flex;
      align-items: center;
      border: 1px solid #868e96;
      border-radius: 50%;
      padding: 1px 4px 0 4px;
      font-size: 8px;
      color: #868e96;
      margin-left: 0.3rem;
    }
  }

  &__menu {
    position: absolute;
    top: 1rem;
    right: 1rem;

    @media only screen and (min-width: 1400px) {
      right: 4%;
    }

    &__item {
      a {
        font-weight: 400 !important;
      }
    }
  }

  &__attachments {
    font-size: 12px;
    color: #ced4da;
  }
}

a.link-unstyled {
  // reset css from App.vue
  text-align: left;
  text-decoration: none;
  font-weight: unset;
  transition: unset;
  letter-spacing: unset;
}

.document-table-row__name-signers-items {
  flex: 1;
  & > div {
    width: fit-content;
  }
}
</style>
